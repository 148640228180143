<template>
  <div :class="aspect">
    <iframe
      ref="elementRef"
      :src="url"
      :allow="allow"
      :title:="title"
      frameborder="0"
      allowfullscreen
      class="size-full"
      loading="lazy"
    ></iframe>
  </div>
</template>

<script setup lang="ts">
import type { OEmbedPlatformResponse } from '~/rpcMethods/oEmbed'

/**
 * Greatest common divisor
 */
const gcd = (x: number, y: number): number => {
  x = Math.abs(x)
  y = Math.abs(y)
  while (y) {
    const t = y
    y = x % y
    x = t
  }

  return x
}

/**
 * Least common multiple
 */
const lcm = (x: number, y: number): number =>
  !x || !y ? 0 : Math.abs((x * y) / gcd(x, y))

const props = defineProps({
  url: {
    type: String as PropType<string>,
    required: true,
  },
  oEmbed: {
    type: Object as PropType<OEmbedPlatformResponse | null | undefined>,
    required: true,
  },
  allow: {
    type: String as PropType<string>,
    required: true,
  },
})

const elementRef = ref<HTMLElement>()

const aspect = computed(() => {
  if (!props.oEmbed?.width || !props.oEmbed?.height) {
    return 'aspect-w-16 aspect-h-9'
  }
  const tmp = lcm(props.oEmbed?.width, props.oEmbed?.height)
  const w = tmp / props.oEmbed?.height
  const h = tmp / props.oEmbed?.width

  if (w > 16 || h > 16) {
    return Math.abs(w / h - 4 / 3) > Math.abs(w / h - 16 / 9)
      ? 'aspect-w-16 aspect-h-9'
      : 'aspect-w-4 aspect-h-3'
  }

  return `aspect-w-${tmp / props.oEmbed?.height} aspect-h-${
    tmp / props.oEmbed?.width
  }`
})

// used in template
const title = computed(() => props.oEmbed?.title ?? '')
</script>

<style scoped>
iframe {
  content-visibility: auto;
}
</style>
